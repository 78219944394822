import React, { useEffect, useState, Suspense } from "react";
import { useDispatch } from "react-redux";
import { Button } from "@my-scoot/component-library-legacy";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";
import IconButton from "@material-ui/core/IconButton";

import LearnMoreModal from "../modals/LearnMoreModal/LearnMoreModal";
import TabLayout from "common/Layouts/TabLayout";
import { InitialSegmentUsedEmail } from "ui/pages/whatsappBroadcast/list/item/components/InitialSegmentUsedEmail";

import { useStyles } from "../composeEmails.styles";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import { EXLY_GLOBAL__SET_SHOW_BOTTOM_NAV } from "redux/actions";
import { getParamValueFromRouteProps } from "utils/Utils";
import { orgPermissions, RESOURCE_KEYS } from "utils/OrgPermissions";
import {
  EMAIL_CAMPAIGNS_LEARN_MORE_HREFS,
  FEATURE_TAB_CONFIG,
  tabValues,
} from "../data";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import { useToggleState } from "utils/hooks";
import { CheckCreditExpiry } from "common/Components/UBP/CheckCreditExpiry";
import { FEATURE_KEYS } from "features/MySubscriptions/constants/MySubscriptions.constants";
import LearnMoreCta from "features/Common/modules/LearnMore/modules/LearnMoreCta/LearnMoreCta";
import { EmailCampaignLayoutContext } from "../CustomEmail.utils";

const ComposeOptionsModal = React.lazy(() =>
  import("../modals/ComposeOptionsModal/ComposeOptionsModal")
);

const EmailCampaignLayout = ({ selectedTab, children, ...props }) => {
  const isDesktop = useDesktopMediaQuery();
  const classes = useStyles({ isDesktop });
  const dispatch = useDispatch();

  const [showInitialSegmentModal, setShowInitialSegmentModal] = useState(false);
  const [openLearnMoreModal, setOpenLearnMoreModal] = useState(false);
  const [
    isComposeOptionModalOpen,
    openComposeOptionModal,
    closeComposeOptionModal,
  ] = useToggleState(false);

  const hasWriteAccess = orgPermissions.hasFeatureWriteAccess(
    RESOURCE_KEYS.SECTIONS.CUSTOM_MAILS
  );

  const checkSegment = getParamValueFromRouteProps(props, "check_segment");
  const canReadSegments = !orgPermissions.isNavigationItemHidden(
    RESOURCE_KEYS.SECTIONS.SEGMENTATION
  );

  useEffect(() => {
    dispatch({ type: EXLY_GLOBAL__SET_SHOW_BOTTOM_NAV, payload: false });

    return () => {
      dispatch({ type: EXLY_GLOBAL__SET_SHOW_BOTTOM_NAV, payload: true });
    };
  }, [isDesktop]);

  useEffect(() => {
    if (checkSegment && canReadSegments) {
      setShowInitialSegmentModal(true);
    }
  }, []);

  const handleCompose = () => {
    if (!hasWriteAccess) return;
    openComposeOptionModal();
  };

  return (
    <div className={`${isDesktop && "component-wrapper"} ${classes.root}`}>
      <div className={classes.headerRoot}>
        <div className={classes.titleContainer}>
          {!isDesktop && (
            <IconButton
              className={classes.backBtn}
              onClick={() => window.history.go(-1)}
            >
              <ArrowBackRoundedIcon />
            </IconButton>
          )}
          <span className={classes.title}>Email Marketing</span>
        </div>
        <div className={classes.headerRightContainer}>
          <LearnMoreCta
            href={EMAIL_CAMPAIGNS_LEARN_MORE_HREFS.EMAIL_CAMPAIGNS}
          />

          {isDesktop && (
            <Button
              color="primary"
              size="medium"
              onClick={handleCompose}
              disabled={!hasWriteAccess}
            >
              Compose Email
            </Button>
          )}
        </div>
      </div>

      {!isDesktop && (
        <CheckCreditExpiry feature_key={FEATURE_KEYS.email_marketing.key} />
      )}

      <div
        className={
          isDesktop
            ? "component-wrapper-y"
            : `component-wrapper ${classes.mb_68}`
        }
      >
        <TabLayout selectedTab={selectedTab} tabConfig={FEATURE_TAB_CONFIG}>
          <EmailCampaignLayoutContext.Provider value={{ handleCompose }}>
            {children}
          </EmailCampaignLayoutContext.Provider>
        </TabLayout>
      </div>

      {!isDesktop && (
        <div className={classes.stickyButton}>
          <Button
            fullWidth
            size="medium"
            color="primary"
            onClick={handleCompose}
            disabled={!hasWriteAccess}
          >
            Compose
          </Button>
        </div>
      )}

      <LearnMoreModal
        open={openLearnMoreModal}
        onClose={() => setOpenLearnMoreModal(false)}
      />

      {isComposeOptionModalOpen ? (
        <Suspense fallback={<></>}>
          <ComposeOptionsModal
            open={isComposeOptionModalOpen}
            onClose={closeComposeOptionModal}
            isDripTabOpen={selectedTab === tabValues.DRIP_CAMPAIGN}
          />
        </Suspense>
      ) : null}

      {showInitialSegmentModal ? <InitialSegmentUsedEmail /> : null}
    </div>
  );
};

export default withComponentLibraryTheme(EmailCampaignLayout);
