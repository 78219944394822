// 3rd party and inbuilt
import React, { useState } from "react";
import { List, useListController } from "react-admin";

// Styles and others
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import {
  activeDripemptyStateProps,
  dripTabValues,
  EMAIL_CAMPAIGNS_LEARN_MORE_HREFS,
  tabValues,
} from "../data";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import style from "./ActiveDrip.module.css";
import { fieldConfig, getDripHeader } from "./activeDripConfig";
import { useToggleState } from "utils/hooks";
import useActiveDripActions from "./useActiveDripActions";

// Components
import EmailCampaignLayout from "../layouts/EmailCampaignLayout";
import DripTabLayout from "../layouts/DripTabLayout";
import EmailDripItem from "../components/EmailDripItem/EmailDripItem";
import ExlyTablePagination from "common/Components/ExlyTable/ExlyTablePagination";
import ActionDrawer from "common/Components/ActionDrawer/ActionDrawer";
import PauseDripModal from "../modals/PauseDripModal/PauseDripModal";
import EndDripModal from "../modals/EndDripModal/EndDripModal";
import EmailDripItemLoader from "../components/EmailDripItem/EmailDripItemLoader";
import { CheckCreditExpiry } from "common/Components/UBP/CheckCreditExpiry";
import { FEATURE_KEYS } from "features/MySubscriptions/constants/MySubscriptions.constants";
import DripCampaignEmptyState from "../components/DripCampaignEmptyState/DripCampaignEmptyState";

function ActiveDrip(props) {
  const isDesktop = useDesktopMediaQuery();
  const { data, loading } = useListController(props);
  const activeDrips = Object.values(data);
  const [selectedDrip, setSelectedDrip] = useState(null);
  const [isMobileOptionsOpen, openMobileOptions, closeMobileOptions] =
    useToggleState(false);
  const [isPauseModalOpen, openPauseModal, closePauseModal] =
    useToggleState(false);
  const [isEndModalOpen, openEndModal, closeEndModal] = useToggleState(false);

  const { writeDisabled, handleEdit, actions } = useActiveDripActions({
    selectedDrip,
    setSelectedDrip,
    closeMobileOptions,
    openPauseModal,
    openEndModal,
  });

  return (
    <>
      {isDesktop && (
        <CheckCreditExpiry feature_key={FEATURE_KEYS.email_marketing.key} />
      )}
      <EmailCampaignLayout selectedTab={tabValues.DRIP_CAMPAIGN} {...props}>
        <DripTabLayout selectedTab={dripTabValues.ACTIVE}>
          <List
            {...props}
            component={"div"}
            exporter={false}
            perPage={50}
            empty={
              <DripCampaignEmptyState
                {...activeDripemptyStateProps}
                videoSrc={EMAIL_CAMPAIGNS_LEARN_MORE_HREFS.DRIP_CAMPAIGNS_VIDEO}
              />
            }
            pagination={
              <ExlyTablePagination
                classes={{ mobileWrapper: style.mobilePaginationWrapper }}
                borderedTop
              />
            }
            actions={null}
          >
            <div className={style.listWrap}>
              {loading ? (
                <EmailDripItemLoader showStats showExtraRow={!isDesktop} />
              ) : (
                activeDrips.map((dripItem) => (
                  <EmailDripItem
                    key={dripItem.uuid}
                    data={dripItem}
                    fieldConfig={fieldConfig}
                    showStats
                    actions={actions}
                    onMobileActionsClick={(record) => {
                      setSelectedDrip(record);
                      openMobileOptions();
                    }}
                    writeDisabled={writeDisabled}
                    handleEdit={handleEdit}
                    headerFormatter={getDripHeader}
                  />
                ))
              )}
            </div>
          </List>
          <ActionDrawer
            open={isMobileOptionsOpen}
            onClose={() => {
              setSelectedDrip(null);
              closeMobileOptions();
            }}
            actions={actions}
          />
          <PauseDripModal
            open={isPauseModalOpen}
            selectedDrip={selectedDrip}
            onClose={() => {
              setSelectedDrip(null);
              closePauseModal();
            }}
          />
          <EndDripModal
            open={isEndModalOpen}
            selectedDrip={selectedDrip}
            onClose={() => {
              setSelectedDrip(null);
              closeEndModal();
            }}
          />
        </DripTabLayout>
      </EmailCampaignLayout>
    </>
  );
}

export default withComponentLibraryTheme(ActiveDrip);
